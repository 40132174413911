import React, { type FC } from 'react';

export const LogoIcon: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSecondaryContainer)';

  return (
    <svg style={{ scale: '1.65' }} xmlns="http://www.w3.org/2000/svg" width="36" height="16" viewBox="0 0 36 16" fill="none">
      <path d="M0.799805 11.8352V0.926147H2.77601V10.1786H7.5807V11.8352H0.799805Z" fill={fillColor} />
      <path d="M12.7796 11.995C11.9806 11.995 11.2881 11.8193 10.7021 11.4677C10.1162 11.1161 9.66167 10.6243 9.33851 9.9922C9.01891 9.3601 8.85911 8.62146 8.85911 7.77629C8.85911 6.93112 9.01891 6.19071 9.33851 5.55505C9.66167 4.9194 10.1162 4.42579 10.7021 4.07423C11.2881 3.72267 11.9806 3.54689 12.7796 3.54689C13.5786 3.54689 14.271 3.72267 14.857 4.07423C15.4429 4.42579 15.8957 4.9194 16.2153 5.55505C16.5384 6.19071 16.7 6.93112 16.7 7.77629C16.7 8.62146 16.5384 9.3601 16.2153 9.9922C15.8957 10.6243 15.4429 11.1161 14.857 11.4677C14.271 11.8193 13.5786 11.995 12.7796 11.995ZM12.7902 10.4503C13.2235 10.4503 13.5857 10.3313 13.8769 10.0934C14.1681 9.85193 14.3847 9.52878 14.5267 9.12395C14.6723 8.71912 14.7451 8.26812 14.7451 7.77096C14.7451 7.27025 14.6723 6.81748 14.5267 6.41265C14.3847 6.00427 14.1681 5.67934 13.8769 5.43787C13.5857 5.19639 13.2235 5.07565 12.7902 5.07565C12.3463 5.07565 11.977 5.19639 11.6823 5.43787C11.3911 5.67934 11.1727 6.00427 11.0271 6.41265C10.885 6.81748 10.814 7.27025 10.814 7.77096C10.814 8.26812 10.885 8.71912 11.0271 9.12395C11.1727 9.52878 11.3911 9.85193 11.6823 10.0934C11.977 10.3313 12.3463 10.4503 12.7902 10.4503Z" fill={fillColor} />
      <path d="M21.8949 15.0739C21.2024 15.0739 20.6076 14.9798 20.1104 14.7916C19.6133 14.6069 19.2138 14.3583 18.9119 14.0458C18.6101 13.7333 18.4006 13.3871 18.2834 13.0071L20.0199 12.5863C20.098 12.7461 20.2116 12.9041 20.3608 13.0604C20.5099 13.2202 20.7106 13.3516 20.9627 13.4546C21.2184 13.5611 21.5398 13.6144 21.9268 13.6144C22.4737 13.6144 22.9265 13.4812 23.2852 13.2149C23.6438 12.9521 23.8232 12.5188 23.8232 11.9151V10.3651H23.7273C23.6278 10.5639 23.4822 10.7681 23.2905 10.9776C23.1023 11.1872 22.8519 11.3629 22.5394 11.505C22.2305 11.647 21.8416 11.7181 21.3729 11.7181C20.7443 11.7181 20.1744 11.5707 19.663 11.2759C19.1552 10.9776 18.7504 10.5337 18.4485 9.94426C18.1502 9.35122 18.0011 8.60903 18.0011 7.7177C18.0011 6.81926 18.1502 6.06109 18.4485 5.44319C18.7504 4.82174 19.157 4.35122 19.6683 4.03162C20.1797 3.70846 20.7496 3.54689 21.3782 3.54689C21.8576 3.54689 22.2518 3.62856 22.5607 3.79191C22.8732 3.95172 23.1218 4.14525 23.3065 4.37253C23.4911 4.59625 23.6314 4.80754 23.7273 5.0064H23.8338V3.65342H25.7354V11.9684C25.7354 12.668 25.5685 13.2468 25.2347 13.7049C24.9009 14.163 24.4446 14.5057 23.8658 14.733C23.2869 14.9602 22.63 15.0739 21.8949 15.0739ZM21.9109 10.2053C22.3192 10.2053 22.6673 10.1058 22.9549 9.90697C23.2425 9.70811 23.4609 9.42224 23.6101 9.04937C23.7592 8.6765 23.8338 8.22906 23.8338 7.70704C23.8338 7.19213 23.7592 6.74113 23.6101 6.35406C23.4645 5.96699 23.2479 5.66691 22.9602 5.45385C22.6761 5.23723 22.3264 5.12892 21.9109 5.12892C21.4812 5.12892 21.1225 5.24078 20.8349 5.4645C20.5472 5.68822 20.3306 5.99539 20.185 6.38602C20.0394 6.77309 19.9666 7.21343 19.9666 7.70704C19.9666 8.20775 20.0394 8.64632 20.185 9.02274C20.3342 9.39561 20.5526 9.6868 20.8402 9.89632C21.1314 10.1023 21.4883 10.2053 21.9109 10.2053Z" fill={fillColor} />
      <path d="M31.2805 11.995C30.4815 11.995 29.7891 11.8193 29.2031 11.4677C28.6172 11.1161 28.1626 10.6243 27.8395 9.9922C27.5199 9.3601 27.3601 8.62146 27.3601 7.77629C27.3601 6.93112 27.5199 6.19071 27.8395 5.55505C28.1626 4.9194 28.6172 4.42579 29.2031 4.07423C29.7891 3.72267 30.4815 3.54689 31.2805 3.54689C32.0795 3.54689 32.772 3.72267 33.358 4.07423C33.9439 4.42579 34.3967 4.9194 34.7163 5.55505C35.0394 6.19071 35.201 6.93112 35.201 7.77629C35.201 8.62146 35.0394 9.3601 34.7163 9.9922C34.3967 10.6243 33.9439 11.1161 33.358 11.4677C32.772 11.8193 32.0795 11.995 31.2805 11.995ZM31.2912 10.4503C31.7244 10.4503 32.0866 10.3313 32.3778 10.0934C32.669 9.85193 32.8857 9.52878 33.0277 9.12395C33.1733 8.71912 33.2461 8.26812 33.2461 7.77096C33.2461 7.27025 33.1733 6.81748 33.0277 6.41265C32.8857 6.00427 32.669 5.67934 32.3778 5.43787C32.0866 5.19639 31.7244 5.07565 31.2912 5.07565C30.8473 5.07565 30.478 5.19639 30.1832 5.43787C29.892 5.67934 29.6737 6.00427 29.5281 6.41265C29.386 6.81748 29.315 7.27025 29.315 7.77096C29.315 8.26812 29.386 8.71912 29.5281 9.12395C29.6737 9.52878 29.892 9.85193 30.1832 10.0934C30.478 10.3313 30.8473 10.4503 31.2912 10.4503Z" fill={fillColor} />
    </svg>
  )
}
